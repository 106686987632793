import React, { useEffect, useState } from 'react'
import axios from 'axios';
// import Header from '../../components/Header'
// import Footer from '../../components/Footer'

// import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
// import { MHListings, usStatesWithCode } from "../../constants/index";
import { Base64 } from "js-base64";

export const usStatesWithCode = [
    { "code": "AL", "name": "Alabama" },
    { "code": "AK", "name": "Alaska" },
    { "code": "AZ", "name": "Arizona" },
    { "code": "AR", "name": "Arkansas" },
    { "code": "CA", "name": "California" },
    { "code": "CO", "name": "Colorado" },
    { "code": "CT", "name": "Connecticut" },
    { "code": "DE", "name": "Delaware" },
    { "code": "DC", "name": "District Of Columbia" },
    { "code": "FL", "name": "Florida" },
    { "code": "GA", "name": "Georgia" },
    { "code": "HI", "name": "Hawaii" },
    { "code": "ID", "name": "Idaho" },
    { "code": "IL", "name": "Illinois" },
    { "code": "IN", "name": "Indiana" },
    { "code": "IA", "name": "Iowa" },
    { "code": "KS", "name": "Kansas" },
    { "code": "KY", "name": "Kentucky" },
    { "code": "LA", "name": "Louisiana" },
    { "code": "ME", "name": "Maine" },
    { "code": "MD", "name": "Maryland" },
    { "code": "MA", "name": "Massachusetts" },
    { "code": "MI", "name": "Michigan" },
    { "code": "MN", "name": "Minnesota" },
    { "code": "MS", "name": "Mississippi" },
    { "code": "MO", "name": "Missouri" },
    { "code": "MT", "name": "Montana" },
    { "code": "NE", "name": "Nebraska" },
    { "code": "NV", "name": "Nevada" },
    { "code": "NH", "name": "New Hampshire" },
    { "code": "NJ", "name": "New Jersey" },
    { "code": "NM", "name": "New Mexico" },
    { "code": "NY", "name": "New York" },
    { "code": "NC", "name": "North Carolina" },
    { "code": "ND", "name": "North Dakota" },
    { "code": "OH", "name": "Ohio" },
    { "code": "OK", "name": "Oklahoma" },
    { "code": "OR", "name": "Oregon" },
    { "code": "PA", "name": "Pennsylvania" },
    { "code": "PR", "name": "Puerto Rico" },
    { "code": "RI", "name": "Rhode Island" },
    { "code": "SC", "name": "South Carolina" },
    { "code": "SD", "name": "South Dakota" },
    { "code": "TN", "name": "Tennessee" },
    { "code": "TX", "name": "Texas" },
    { "code": "UT", "name": "Utah" },
    { "code": "VT", "name": "Vermont" },
    { "code": "VA", "name": "Virginia" },
    { "code": "VI", "name": "Virgin Islands" },
    { "code": "WA", "name": "Washington" },
    { "code": "WV", "name": "West Virginia" },
    { "code": "WI", "name": "Wisconsin" },
    { "code": "WY", "name": "Wyoming" }
];

const DetailsPlain = () => {
    // const hklocation = useLocation();
    // const navigate = useNavigate();
    let { id, alias } = useParams();

    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id !== undefined && id !== "") {
            // console.log("id: ", Base64.decode(id.toString().trim()));
            // console.log("alias: ", alias);

            // // console.log("state name: ", usStatesWithCode.find(s => s["code"].toUpperCase() === details.state.toUpperCase())["name"]);
            // //console.log(MHListings.filter(listing => listing["_irow"] === Base64.decode(id.toString().trim())));
            // setDetails(...MHListings.filter(listing => listing["_irow"] === Base64.decode(id.toString().trim())));
            // //let stateCode = state.toUpperCase().split("-")[0];
            // // console.log(MHListings.filter(listing => listing["state"] === stateCode));
            // //setListings(MHListings.filter(listing => listing["state"] === stateCode));
            fetch_Listing_details(Base64.decode(id.toString().trim()));
        }
    }, [id, alias])

    const fetch_Listing_details = (id) => {
        const json = JSON.stringify({ "irow": id });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_listing_details`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                //console.log(response.data.results);
                setDetails(response.data.results[0]);
                setLoading(false);
                //setDetails(...MHListings.filter(listing => listing["_irow"] === Base64.decode(id.toString().trim())));

                if (document.getElementsByClassName('progress-wrap').length > 0) {
                    document.getElementsByClassName('progress-wrap')[0].remove();
                }
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            setLoading(false);
        });
    }

    return (
        <>
            {/* <Header /> */}

            <div className="bg-img">
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
                <img src="/assets/img/bg/feature-bg-03.png" alt="img" className="bgimg3" />
            </div>

            <div className="content" style={{ transform: 'none' }}>
                <div className="container" style={{ transform: 'none' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="serv-profile">
                                {!loading ?
                                    <>
                                        <h2>{details.name1 + (details.name2 !== "" ? (" " + details.name2) : "")}</h2>
                                        <ul>
                                            <li>
                                                <span className="badge">{details.city}</span>
                                            </li>
                                            {/* {(details.state !== undefined && details.state !== "") && (<li className="service-map"><i className="feather-map-pin" /> {usStatesWithCode.find(s => s["code"].toUpperCase() === details.state.toUpperCase())["name"]}, USA</li>)} */}
                                            {(details.state !== undefined && details.state !== "") && (<li className="service-map"><i className="feather-map-pin" /> {details["street1"]}, {details["city"]}, {usStatesWithCode.find(s => s["code"].toUpperCase() === details.state.toUpperCase())["name"]} {details["zip"]}</li>)}
                                        </ul>
                                    </>
                                    :
                                    <>
                                        <div className='skeleton' style={{ minHeight: "36px", marginBottom: "8px", borderRadius: "4px" }}></div>
                                        <ul>
                                            <li>
                                                <span className="badge skeleton" style={{ minHeight: "32px", minWidth: "120px", verticalPadding: "8px", horizontalPadding: "10px", marginBottom: "15px" }}></span>
                                            </li>
                                            <li className="service-map"><span className="badge skeleton" style={{ minHeight: "32px", minWidth: "300px", marginBottom: "15px" }}></span></li>
                                        </ul>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="service-gal">
                                <div className="row gx-2">
                                    <div className="col-md-12">
                                        {!loading ?
                                            <div className="service-images big-gallery">
                                                {/* <img src="/assets/img/services/service-ban-01.jpg" className="img-fluid" alt="img" /> */}

                                                {/* <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${details.street1 + (details.street2 !== "" ? ", " + details.street2 : "") + ", " + details.city + ", " + details.state + " " + details.zip}&zoom=13&size=1124x262&maptype=roadmap&markers=color:red%7C${details.latitude},${details.longitude}&scale=2&key=AIzaSyDJKK3EmFv-KCX8z0--X30pwVYCSNX_DQg`} className="img-fluid" alt={details.name1} /> */}
                                                <img src={`${process.env.REACT_APP_BASE_API}/images/listings/large/${details["_irow"]}.png`} className="img-fluid" alt={details.name1} loading="lazy" />
                                            </div>
                                            :
                                            <div className="service-images big-gallery">
                                                <div className="img-fluid skeleton" style={{ minHeight: "450px", minWidth: "100%", borderRadius: "4px" }}></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ transform: 'none' }}>
                        <div className="col-lg-8">
                            {!loading ?
                                (details.services !== undefined && JSON.parse(details.services).length > 0) && JSON.parse(details.services).map((service, index) => (
                                    <div className="service-wrap" key={index}>
                                        <h5>{service.f1}</h5>
                                        <p>{service.f3}</p>
                                    </div>
                                ))
                                :
                                [...Array(8)].map((u, index) =>
                                (
                                    <div className="service-wrap" key={index}>
                                        <div className='skeleton w-100' style={{ minHeight: "22px", marginBottom: "20px" }}></div>
                                        <div className='skeleton w-100' style={{ minHeight: "80px", marginBottom: "20px" }}></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="col-lg-4 theiaStickySidebar">
                            <div className="theiaStickySidebar">
                                <div className="card card-provide mb-0">
                                    <div className="card-body">
                                        <div className="package-widget">
                                            {!loading ?
                                                <div className='row'>
                                                    {
                                                        details.state !== undefined && (<div className="col-md-12">
                                                            <div className="provide-box">
                                                                <span><i className="feather-map-pin" /></span>
                                                                <div className="provide-info">
                                                                    <h6>Address</h6>
                                                                    <p>{details["street1"]}, {details["city"]}, {usStatesWithCode.find(s => s["code"].toUpperCase() === details.state.toUpperCase())["name"]} {details["zip"]}</p>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    }
                                                    <div className="col-md-12">
                                                        <div className="provide-box">
                                                            <span><i className="feather-globe" /></span>
                                                            <div className="provide-info">
                                                                <h6>Website</h6>
                                                                <p>{(details.website !== undefined && details.website.trim() !== "" && details.website.trim() !== "https://" && details.website.trim() !== "http://") ? details.website : "Not Available"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="provide-box">
                                                            <span><i className="feather-phone" /></span>
                                                            <div className="provide-info">
                                                                <h6>Phone</h6>
                                                                {(details.phone !== undefined && details.phone !== "") ?
                                                                    (
                                                                        <Link to={`tel:${details.phone.split("x")[0].trim()}`}>
                                                                            <p style={{ color: "#4c40ed", fontWeight: "bold" }}>{details.phone.split("x")[0].trim()}</p>
                                                                        </Link>
                                                                    )
                                                                    :
                                                                    ("Not Available")
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <div className="provide-box">
                                                            <span><i className="feather-map-pin" /></span>
                                                            <div className="provide-info">
                                                                <h6>Address</h6>
                                                                <p className="skeleton" style={{ minHeight: "49px", width: "150px", verticalPadding: "8px", horizontalPadding: "10px", marginBottom: "15px" }}></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="provide-box">
                                                            <span><i className="feather-globe" /></span>
                                                            <div className="provide-info">
                                                                <h6>Website</h6>
                                                                <p className="skeleton" style={{ minHeight: "29px", width: "150px", verticalPadding: "8px", horizontalPadding: "10px", marginBottom: "15px" }}></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="provide-box">
                                                            <span><i className="feather-phone" /></span>
                                                            <div className="provide-info">
                                                                <h6>Phone</h6>
                                                                <p className="skeleton" style={{ minHeight: "29px", width: "150px", verticalPadding: "8px", horizontalPadding: "10px", marginBottom: "15px" }}></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="resize-sensor" style={{ position: 'absolute', inset: '0px', overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
                                    <div className="resize-sensor-expand" style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
                                        <div style={{ position: 'absolute', left: '0px', top: '0px', transition: 'all 0s ease 0s', width: '366px', height: '1498px' }} /></div>
                                    <div className="resize-sensor-shrink" style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
                                        <div style={{ position: 'absolute', left: 0, top: 0, transition: '0s', width: '200%', height: '200%' }} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </>
    )
}

export default DetailsPlain