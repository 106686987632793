import React from 'react';
// import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
// import './pagination.scss';

const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 0,
        currentPage,
        pageSize
    } = props;

    // console.log(props);

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="blog-pagination rev-page">
                    <nav>
                        <ul className="pagination justify-content-center">
                            {/* Left navigation arrow */}
                            <li className={`page-item${currentPage === 1 && " disabled"}`}
                                // className={classnames('pagination-item', {
                                //     disabled: currentPage === 1
                                // })}
                                onClick={onPrevious}
                            >
                                <a className="page-link page-prev" href="javascript:void(0);" tabIndex={-1}><i className="fa-solid fa-arrow-left me-1" /> PREV</a>
                            </li>
                            {paginationRange.map((pageNumber, index) => {

                                // If the pageItem is a DOT, render the DOTS unicode character
                                if (pageNumber === DOTS) {
                                    return <li className="pagination-item dots" key={index}>&#8230;</li>;
                                }

                                // Render our Page Pills
                                return (
                                    <li className={`page-item${pageNumber === currentPage && " active"}`}
                                        // className={classnames('pagination-item', {
                                        //     selected: pageNumber === currentPage
                                        // })}
                                        onClick={() => onPageChange(pageNumber)}
                                        key={index}
                                    >
                                        <a className="page-link" href="javascript:void(0);">{pageNumber}</a>
                                    </li>
                                );
                            })}
                            {/*  Right Navigation arrow */}
                            <li className={`page-item${currentPage === lastPage && " disabled"}`}
                                // className={classnames('pagination-item', {
                                //     disabled: currentPage === lastPage
                                // })}
                                onClick={onNext}
                            >
                                <a className="page-link page-next" href="javascript:void(0);">NEXT <i className="fa-solid fa-arrow-right ms-1" /></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div >
    );
};

export default Pagination;