//import logo from './logo.svg';
//import './App.css';
import { Routes, Route } from "react-router-dom";

// import Home from './containers/pages/Home';
// import Search from "./containers/pages/Search";
// import Details from "./containers/pages/Details";
import SearchPlain from "./containers/pages/SearchPlain";
import DetailsPlain from "./containers/pages/DetailsPlain";
// import ImageResolver from "./containers/pages/ImageResolver";

function App() {
  return (
    <Routes>
      {/* <Route path="/" element={<Home />} /> */}

      {/* <Route path="/search/:state" element={<Search />} /> */}
      <Route path="/search-iframe/:state" element={<SearchPlain />} />

      {/* <Route path="/search/:state/:city" element={<Search />} /> */}
      <Route path="/search-iframe/:state/:city" element={<SearchPlain />} />
      
      {/* <Route path="/details/:alias" element={<Details />} /> */}
      <Route path="/details-iframe/:alias" element={<DetailsPlain />} />

      {/* <Route path="/details/:id/:alias" element={<Details />} /> */}
      <Route path="/details-iframe/:id/:alias" element={<DetailsPlain />} />
      {/* <Route path="/image-resolver" element={<ImageResolver />} /> */}

      {/* <Route path="/search/:state" element={<V2Search />} />
        <Route path="/search/:state/:city" element={<V2Search />} />
        <Route path="/details/:alias" element={<V2Details />} />
        <Route path="/details/:id/:alias" element={<V2Details />} /> */}

      {/* <Route path="/opioid-addiction" element={<OpioidAddiction />} />
        <Route path="/signs-of-opioid-addiction" element={<SignsOfOpioidAddiction />} />
        <Route path="/effects-of-opioid-addiction" element={<EffectsOfOpioidAddiction />} />
        <Route path="/signs-of-opioid-overdose" element={<SignsOfOpioidOverdose />} />
        <Route path="/opioid-addiction-risk-factors" element={<OpioidAddictionRiskFactors />} />
        <Route path="/am-i-addicted-to-opioids" element={<AmIAddictedToOpioids />} />

        <Route path="/find-opioid-treatment" element={<FindOpioidTreatment />} />
        <Route path="/detox" element={<Detox />} />
        <Route path="/residential-treatment" element={<ResidentialTreatment />} />
        <Route path="/medication-assisted-treatment" element={<MedicationAssistedTreatment />} />
        <Route path="/virtual-treatment" element={<VirtualTreatment />} />
        <Route path="/outpatient-treatment" element={<OutpatientTreatment />} />
        <Route path="/paying-for-treatment" element={<PayingForTreatment />} />

        <Route path="/success-stories-recovery" element={<SuccessStoriesRecovery />} />
        <Route path="/opioid-addiction-stories" element={<OpioidAddictionStories />} />
        <Route path="/sobriety-engine" element={<SobrietyEngine />} />
        <Route path="/recovery-resources" element={<RecoveryResources />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/post"element={<Post /> } />
        <Route path="/post/:postid/:slug"element={<Post /> } />

        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forget_password" element={<ForgetPassword />} />
        <Route path="/admin/reset_password/:token" element={<ResetPassword />} />        
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/change_password" element={<ChangePassword />} />
        <Route path="/admin/verify/:token" element={<Verify />} />
        <Route path="/admin/users" element={<Users />} />

        <Route path="/admin/profile" element={<Profile />} />
        <Route path="/admin/profile/:user_token" element={<Profile />} />

        <Route path="/admin/all_listings" element={<AllListings />} />
        <Route path="/admin/add_listing" element={<AddListing />} />
        <Route path="/admin/edit_listing/:listing_id" element={<EditListing />} />
        <Route path="/admin/add_review/:listing_id" element={<AddReview />} />
        <Route path="/admin/my_listing" element={<MyListing />} />
        <Route path="/admin/my_incomplete_listing" element={<MyIncompleteListing />} />

        <Route path="/admin/add_post" element={<AddPost />} />
        <Route path="/admin/edit_post/:postid" element={<EditPost />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/404" element={<NotFound />} /> */}
    </Routes>
  );
}

export default App;
