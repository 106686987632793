import React, { useEffect, useState } from 'react'
import axios from 'axios';
// import Header from '../../components/Header'
// import Footer from '../../components/Footer'
import Pagination from '../../components/Pagination';

import { useParams, Link } from "react-router-dom";
// import { Base64 } from "js-base64";
//import { MHListings } from "../../constants/index";
import { base64_encode } from '../../func/index';

let PageSize = 24;

const SearchPlain = () => {
    let { state, city } = useParams();
    const [MHListings, setMHListing] = useState([]);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);

    const [citiesInState, setCitiesInState] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [showResetLink, setShowResetLink] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [view, setView] = useState('list');
    // const [view, setView] = useState('grid');

    // const currentTableData = useMemo(() => {
    //     const firstPageIndex = (currentPage - 1) * PageSize;
    //     const lastPageIndex = firstPageIndex + PageSize;
    //     return listings.slice(firstPageIndex, lastPageIndex);
    // }, [currentPage]);

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return listings.slice(firstPageIndex, lastPageIndex);
    }

    useEffect(() => {
        if (state !== undefined && state !== "") {
            // console.log("state: ", state);
            // console.log("city: ", city);
            let stateCode = state.toUpperCase().split("-")[0];
            fetch_Listing(stateCode.trim());
        }
    }, [state, city])

    useEffect(() => {
        if (listings.length > 0) {
            //window["bindCollapsible"]();
            bindCollapsible();

            let stateCode = state.toUpperCase().split("-")[0];
            if (MHListings.filter(listing => listing["state"] === stateCode).length > listings.length)
                setShowResetLink(true);
            else
                setShowResetLink(false);
        }
    }, [listings, currentPage, view])

    const fetch_Listing = (state) => {
        const json = JSON.stringify({ "state": state });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_states_listing`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setMHListing(response.data.results)
                // console.log(response.data.results);
                setLoading(false);

                let stateCode = state.toUpperCase().split("-")[0];
                //console.log(MHListings.filter(listing => listing["state"] === stateCode));
                setListings(response.data.results.filter(listing => listing["state"] === stateCode));

                let unique_cities = [
                    ...new Set(response.data.results.filter(listing => listing["state"] === stateCode).map((element) => element.city)),
                ];
                //console.log(unique_cities);            

                var tempSetSelectedCities = [];
                unique_cities.forEach((city) => {
                    tempSetSelectedCities.push({ "city": city, "selected": true });
                });
                setSelectedCities(tempSetSelectedCities);
                setCitiesInState([...unique_cities]);

                if (document.getElementsByClassName('progress-wrap').length > 0) {
                    document.getElementsByClassName('progress-wrap')[0].remove();
                }
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            setLoading(false);
        });
    }

    function bindCollapsible() {
        //console.log("bindCollapsible called");
        var coll = document.getElementsByClassName("collapsible");
        var i;

        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function () {
                this.classList.toggle("active");
                //console.log(((this.parentNode).parentNode).parentNode);
                if (this.classList.contains('active'))
                    this.innerText = "Hide Details";
                else
                    this.innerText = "Show Details";
                const content = (((this.parentNode).parentNode).parentNode).getElementsByClassName("collapsiblecontent")[0];
                //console.log("content: ", content);
                //var content = this.nextElementSibling;
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + "px";
                }
            });
        }
    }

    const CreateResults = () => {
        return currentTableData().length > 0 && currentTableData().map((item, index) => (
            <div className="col-xl-4 col-md-6 mb-4" key={index}>
                <div className="service-widget servicecontent h-100">
                    <div className="service-img">
                        <Link to={`${process.env.REACT_APP_WEBSITE_BASE_URL}/details/${base64_encode(item["_irow"].toString())}/${item["name1"].toLowerCase().replace(/[^a-z0-9-]/gi, '-')}`} target='_parent'>
                            {/* <img className="img-fluid serv-img" alt="Service Image" src="/assets/img/services/service-04.jpg" /> */}
                            {/* <iframe className="img-fluid serv-img" src={`https://maps.google.com/maps?q=${item.street1 + (item.street2 !== "" ? ", " + item.street2 : "") + ", " + item.city + ", " + item.state + " " + item.zip}&t=&z=13&ie=UTF8&iwloc=&output=embed&`} title={`${item.name1} ${item.name2}`} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/> */}
                            {/* <img className="img-fluid serv-img" alt="Service Image" src={`https://maps.googleapis.com/maps/api/staticmap?markers=${item.zip},%20${item.street1 + (item.street2 !== "" ? ", " + item.street2 : "") + ", " + item.city + ", " + item.state}&size=350x240&key=AIzaSyDJKK3EmFv-KCX8z0--X30pwVYCSNX_DQg`} /> */}

                            {/* <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${item.street1 + (item.street2 !== "" ? ", " + item.street2 : "") + ", " + item.city + ", " + item.state + " " + item.zip}&zoom=15&size=350x240&maptype=roadmap&markers=color:red%7C${item.latitude},${item.longitude}&key=AIzaSyDJKK3EmFv-KCX8z0--X30pwVYCSNX_DQg`} className="img-fluid serv-img" alt={item.name1} loading="lazy"/> */}
                            <img src={`${process.env.REACT_APP_BASE_API}/images/listings/mini/${item["_irow"]}.png`} className="img-fluid serv-img" alt={item.name1} loading="lazy" />
                        </Link>
                        <div className="fav-item">
                            <span className="item-cat">{item.city}</span>
                        </div>
                        {/* <div className="fav-item">
                            <a href="categories.html"><span className="item-cat">Car Wash</span></a>
                            <a href="javascript:void(0)" className="fav-icon">
                                <i className="feather-heart" />
                            </a>
                        </div>
                        <div className="item-info">
                            <a href="providers.html"><span className="item-img"><img src="/assets/img/profiles/avatar-01.jpg" className="avatar" alt="User" /></span></a>
                        </div> */}
                    </div>
                    <div className="service-content pb-0 mb-0">
                        <h3 className="title">
                            <Link to={`${process.env.REACT_APP_WEBSITE_BASE_URL}/details/${base64_encode(item["_irow"].toString())}/${item["name1"].toLowerCase().replace(/[^a-z0-9-]/gi, '-')}`} target='_parent'>
                                {item.name1} {item.name2}
                            </Link>
                        </h3>
                        <p>
                            <i className="feather-map-pin" />{item.street1}{item.street2 !== "" ? `, ${item.street2},` : ","} {item.city}, {item.state} {item.zip}
                            {/* Maryland City, MD, USA */}
                            {/* <span className="rate"><i className="fas fa-star filled" />4.9</span> */}
                        </p>

                        <div className='collapsiblecontent'>
                            {
                                JSON.parse(item.services).filter(s => s.f1 === "Payment Assistance Available").length > 0 &&
                                <>
                                    <b className='infoheading'>Payment Assistance Available</b>
                                    <p className='infodetails'>{JSON.parse(item.services).filter(s => s.f1 === "Payment Assistance Available")[0]["f3"]}</p>
                                </>
                            }

                            {
                                JSON.parse(item.services).filter(s => s.f1 === "Special Programs/Groups Offered").length > 0 &&
                                <>
                                    <b className='infoheading'>Special Programs/Groups Offered</b>
                                    <p className='infodetails'>{JSON.parse(item.services).filter(s => s.f1 === "Special Programs/Groups Offered")[0]["f3"]}</p>
                                </>
                            }

                            {
                                JSON.parse(item.services).filter(s => s.f1 === "Emergency Mental Health Services").length > 0 &&
                                <>
                                    <b className='infoheading'>Emergency Mental Health Services</b>
                                    <p className='infodetails'>{JSON.parse(item.services).filter(s => s.f1 === "Emergency Mental Health Services")[0]["f3"]}</p>
                                </>
                            }

                            {
                                JSON.parse(item.services).filter(s => s.f1 === "Age Groups Accepted").length > 0 &&
                                <>
                                    <b className='infoheading'>Age Groups Accepted</b>
                                    <p className='infodetails'>{JSON.parse(item.services).filter(s => s.f1 === "Age Groups Accepted")[0]["f3"]}</p>
                                </>
                            }
                        </div>

                        <div className="serv-info flex-column">
                            {/* <h6>$50.00</h6> */}
                            {/* <Link to={`/details/${base64_encode(item["_irow"].toString())}/${item["name1"].toLowerCase().replace(/[^a-z0-9-]/gi, '-')}`} className="btn btn-book">
                                Show Details
                            </Link> */}

                            <button className="btn btn-book collapsible w-100 mb-2">
                                Show Details
                            </button>

                            {item.phone !== "" && (<Link to={`tel:${item.phone.split("x")[0].trim()}`} className="btn btn-primary w-100">
                                {item.phone.split("x")[0].trim()}
                            </Link>)}
                        </div>
                    </div>
                </div>
            </div>
        ))
    }

    const CreateResultsLoading = () => {
        return [...Array(24)].map((item, index) => (
            <div className="col-xl-4 col-md-6 mb-4" key={index}>
                <div className="service-widget servicecontent h-100">
                    <div className="service-img">
                        <div className="img-fluid serv-img rounded-2 w-100 skeleton" style={{ minHeight: "176px", borderRadius: "4px" }}></div>
                    </div>
                    <div className="service-content pb-0 mb-0">
                        <div className='title mt-2 mt-md-0 mb-2 w-100 skeleton' style={{ minHeight: "19px", borderRadius: "4px" }}></div>
                        <p><div className="w-100 skeleton" style={{ minHeight: "58px", marginBottom: "15px" }}></div></p>

                        <div className="serv-info flex-column">
                            <div className="btn btn-book w-100 mb-2 skeleton" style={{ minHeight: "43px", borderRadius: "4px" }}></div>

                            <div className="btn btn-primary w-100 skeleton" style={{ minHeight: "43px", borderRadius: "4px" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    }

    const CreateResultList = () => {
        return currentTableData().length > 0 && currentTableData().map((item, index) => (
            <div className='col-12' key={index}>
                <div className="service-list p-0 p-md-4 d-block">
                    <div className="row">
                        <div className="col-md-4 d-flex flex-column justify-content-between">
                            <Link to={`${process.env.REACT_APP_WEBSITE_BASE_URL}/details/${base64_encode(item["_irow"].toString())}/${item["name1"].toLowerCase().replace(/[^a-z0-9-]/gi, '-')}`} target='_parent'>
                                <img className="img-fluid serv-img rounded-2" alt={item.name1} src={`${process.env.REACT_APP_BASE_API}/images/listings/mini/${item["_irow"]}.png`} />
                                {/* style={{ minHeight: "170px", minWidth: "248px" }} */}
                            </Link>
                            {/* <div className="fav-item">
                            <a href="javascript:void(0)" className="fav-icon">
                                <i className="feather-heart" />
                            </a>
                        </div> */}
                            <div className="d-none d-md-block justify-content-between mt-4">
                                <button className="btn btn-secondary collapsible w-100 mb-2">
                                    Show Details
                                </button>

                                {item.phone !== "" && (<Link to={`tel:${item.phone.split("x")[0].trim()}`} className="btn btn-primary w-100">
                                    {item.phone.split("x")[0].trim()}
                                </Link>)}
                            </div>
                        </div>
                        <div className="col-md-8 px-4 px-md-0">
                            {/* <span className="item-cat">Car Wash</span> */}
                            <h3 className="title mt-2 mt-md-0">
                                <Link to={`${process.env.REACT_APP_WEBSITE_BASE_URL}/details/${base64_encode(item["_irow"].toString())}/${item["name1"].toLowerCase().replace(/[^a-z0-9-]/gi, '-')}`} target='_parent'>
                                    {item.name1} {item.name2}
                                </Link>
                            </h3>
                            <p><i className="feather-map-pin" />{item.street1}{item.street2 !== "" ? `, ${item.street2},` : ","} {item.city}, {item.state} {item.zip}</p>
                            {/* <div className="service-pro-img">
                            <img src="assets/img/profiles/avatar-01.jpg" alt="user" />
                            <span><i className="fas fa-star filled" />4.9</span>
                        </div> */}
                            {
                                JSON.parse(item.services).filter(s => s.f1 === "Payment Assistance Available").length > 0 &&
                                <>
                                    <b className='d-none d-md-block'>Payment Assistance Available</b>
                                    <p className='d-none d-md-block'>{JSON.parse(item.services).filter(s => s.f1 === "Payment Assistance Available")[0]["f3"]}</p>
                                </>
                            }
                            <div className="d-flex justify-content-between my-2 d-md-none">
                                <button className="btn btn-secondary collapsible">
                                    Show Details
                                </button>

                                {item.phone !== "" && (<Link to={`tel:${item.phone.split("x")[0].trim()}`} className="btn btn-primary">
                                    {item.phone.split("x")[0].trim()}
                                </Link>)}
                            </div>
                            <div className='collapsiblecontent'>
                                {
                                    JSON.parse(item.services).filter(s => s.f1 === "Payment Assistance Available").length > 0 &&
                                    <>
                                        <b className='d-block d-md-none'>Payment Assistance Available</b>
                                        <p className='d-block d-md-none'>{JSON.parse(item.services).filter(s => s.f1 === "Payment Assistance Available")[0]["f3"]}</p>
                                    </>
                                }

                                {
                                    JSON.parse(item.services).filter(s => s.f1 === "Special Programs/Groups Offered").length > 0 &&
                                    <>
                                        <b>Special Programs/Groups Offered</b>
                                        <p>{JSON.parse(item.services).filter(s => s.f1 === "Special Programs/Groups Offered")[0]["f3"]}</p>
                                    </>
                                }

                                {
                                    JSON.parse(item.services).filter(s => s.f1 === "Emergency Mental Health Services").length > 0 &&
                                    <>
                                        <b>Emergency Mental Health Services</b>
                                        <p>{JSON.parse(item.services).filter(s => s.f1 === "Emergency Mental Health Services")[0]["f3"]}</p>
                                    </>
                                }

                                {
                                    JSON.parse(item.services).filter(s => s.f1 === "Age Groups Accepted").length > 0 &&
                                    <>
                                        <b>Age Groups Accepted</b>
                                        <p className='mb-0'>{JSON.parse(item.services).filter(s => s.f1 === "Age Groups Accepted")[0]["f3"]}</p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    }

    const CreateResultListLoader = () => {
        return [...Array(24)].map((item, index) => (
            <div className='col-12' key={index}>
                <div className="service-list p-0 p-md-4 d-block">
                    <div className="row">
                        <div className="col-md-4 d-flex flex-column justify-content-between">
                            <div className="img-fluid serv-img rounded-2 skeleton" style={{ minHeight: "136px", minWidth: "198px", borderRadius: "4px" }}></div>
                            <div className="d-none d-md-block justify-content-between mt-4">
                                <div className="btn btn-secondary collapsible w-100 mb-2 skeleton" style={{ minHeight: "38px", minWidth: "198px", borderRadius: "4px" }}></div>

                                <div className="btn btn-primary w-100 skeleton" style={{ minHeight: "38px", minWidth: "198px", borderRadius: "4px" }}></div>
                            </div>
                        </div>
                        <div className="col-md-8 px-4 px-md-0">
                            <div className='title mt-2 mt-md-0 mb-2 skeleton' style={{ minHeight: "34px", borderRadius: "4px" }}></div>
                            <p><div className="skeleton" style={{ minHeight: "25px", minWidth: "300px", marginBottom: "15px" }}></div></p>

                            <div className="d-flex justify-content-between my-2 d-md-none">
                                <div className="btn btn-secondary collapsible skeleton" style={{ minHeight: "38px", minWidth: "198px", borderRadius: "4px" }}></div>

                                <div className="btn btn-primary skeleton" style={{ minHeight: "38px", minWidth: "198px", borderRadius: "4px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    }

    const handleCheckboxChange = (event, city) => {
        //console.log(event.target.checked, city);
        //var filtered = selectedCities.filter(function(el) { return el.city !== city });

        const mySelectedCities = [...selectedCities];
        const thisCity = mySelectedCities.find(
            a => a.city === city
        );
        thisCity.selected = event.target.checked;
        //console.log("mySelectedCities: ", mySelectedCities);
        setSelectedCities(mySelectedCities);
    };

    const filter = () => {
        const mySelectedCities = [...selectedCities];
        let stateCode = state.toUpperCase().split("-")[0];
        let temp = MHListings.filter(listing => listing["state"] === stateCode);

        var filteredCities = [];
        mySelectedCities.forEach((c) => {
            if (c.selected)
                filteredCities = [...filteredCities, ...temp.filter(listing => listing["city"] === c.city)];
        })

        setListings([...filteredCities]);
        //console.log("filteredCities: ", filteredCities);
    }

    const resetFilter = () => {
        let stateCode = state.toUpperCase().split("-")[0];
        setListings(MHListings.filter(listing => listing["state"] === stateCode));

        let resetSelectedCities = [];
        citiesInState.forEach((c) => {
            resetSelectedCities.push({ "city": c, "selected": true });
        });
        setSelectedCities([...resetSelectedCities]);
    }

    return (
        <>
            {/* <Header />

            <div className="bg-img">
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
                <img src="/assets/img/bg/feature-bg-03.png" alt="img" className="bgimg3" />
            </div> */}

            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        {!loading ?
                            <div className="col-md-12 col-12">
                                <h2 className="breadcrumb-title">Find Mental Health Center in {state.split("-")[1]}</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Find Mental Health Center in {state.split("-")[1]}</li>
                                    </ol>
                                </nav>
                            </div>
                            :
                            <div className="col-md-12 col-12">
                                <div className='breadcrumb-title skeleton' style={{ minHeight: "50px", marginBottom: "15px" }}></div>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><div className='skeleton' style={{ minHeight: "27px", minWidth: "300px" }}></div></li>
                                    </ol>
                                </nav>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="content" style={{ transform: 'none' }}>
                <div className="container" style={{ transform: 'none' }}>
                    <div className="row" style={{ transform: 'none' }}>
                        <div className="col-lg-3 col-sm-12 theiaStickySidebar">
                            <div className="theiaStickySidebar" style={{ paddingTop: '0px', paddingBottom: '1px', position: 'static', transform: 'none' }}>
                                <div className="filter-div">
                                    <div className="filter-head">
                                        <h5>Filter by</h5>
                                        {/* <a href="#" className="reset-link" onClick={resetFilter}>Reset Filters</a> */}
                                        {showResetLink && <div className="reset-link" onClick={resetFilter}>Reset Filters</div>}
                                    </div>
                                    {/* <div className="filter-content">
                                        <h2>Rehab Name</h2>
                                        <input type="text" className="form-control" placeholder="What are you looking for?" />
                                    </div> */}
                                    {
                                        !loading ?
                                            <div className="filter-content">
                                                <h2>Cities <span><i className="feather-chevron-down" /></span></h2>
                                                <div className="filter-checkbox" id="fill-more" style={{ height: (citiesInState.length > 6 ? "180px" : "auto") }}>
                                                    <ul>
                                                        {citiesInState.map((city, index) => (
                                                            <li key={index}>
                                                                <label className="checkboxs">
                                                                    <input type="checkbox" checked={selectedCities.filter(c => c["city"] === city).length > 0 && selectedCities.filter(c => c["city"] === city)[0].selected} onChange={(e) => handleCheckboxChange(e, city)} />
                                                                    <span><i /></span>
                                                                    <b className="check-content">{city}</b>
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                {citiesInState.length > 6 && (<div id="more" className="more-view">
                                                    Show more <i className="feather-arrow-down-circle ms-1" />
                                                </div>)}
                                            </div>
                                            :
                                            <div className="filter-content">
                                                <h2>Cities <span><i className="feather-chevron-down" /></span></h2>
                                                <div className="filter-checkbox" id="fill-more" style={{ height: (citiesInState.length > 6 ? "180px" : "auto") }}>
                                                    <ul>
                                                        {[...Array(6)].map((u, index) => (
                                                            <li key={index}>
                                                                <label className="checkboxs">
                                                                    <div className='skeleton' style={{ height: "14px", width: "14px", marginRight: "10px" }}></div>
                                                                    <div className='skeleton w-100' style={{ minHeight: "14px" }}></div>
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                    }
                                    {/* <button className="btn btn-primary" onClick={filter} disabled={selectedCities.filter(sc => sc.selected === false).length > 0 ? false : true}>Search</button> */}
                                    <button className="btn btn-primary" onClick={filter} disabled={!loading ? false : true}>Search</button>
                                </div>
                                <div className="resize-sensor" style={{ position: 'absolute', inset: '0px', overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
                                    <div className="resize-sensor-expand" style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
                                        <div style={{ position: 'absolute', left: '0px', top: '0px', transition: 'all 0s ease 0s', width: '295px', height: '1398px' }} />
                                    </div>
                                    <div className="resize-sensor-shrink" style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
                                        <div style={{ position: 'absolute', left: 0, top: 0, transition: '0s', width: '200%', height: '200%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-12">
                            <div className="row sorting-div">
                                <div className="col-lg-4 col-sm-12">
                                    <div className="count-search">
                                        <h6>Found {listings.length} Listings On Search</h6>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-sm-12 d-flex justify-content-end ">
                                    {/* <div className="sortbyset">
                                        <div className="sorting-select">
                                            <select className="form-control select select2-hidden-accessible" data-select2-id={4} tabIndex={-1} aria-hidden="true">
                                                <option data-select2-id={6}>Price Low to High</option>
                                                <option>Price High to Low</option>
                                            </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={5} style={{ width: '100%' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-4v9b-container"><span className="select2-selection__rendered" id="select2-4v9b-container" role="textbox" aria-readonly="true" title="Price Low to High">Price Low to High</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                                        </div>
                                    </div> */}
                                    <div className="grid-listview">
                                        <ul>
                                            <li>
                                                {view === "list" ? <div className="active"><i className="feather-list" /></div> : <div onClick={() => setView('list')}><i className="feather-list" /></div>}
                                            </li>
                                            <li>
                                                {view === "grid" ? <div className="active"><i className="feather-grid" /></div> : <div onClick={() => setView('grid')}><i className="feather-grid" /></div>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                {(view === "grid") && (!loading ? <CreateResults /> : <CreateResultsLoading />)}
                                {(view === "list") && (!loading ? <CreateResultList /> : <CreateResultListLoader />)}
                            </div>
                            {(!loading && listings.length > 0 && listings.length > PageSize) && <Pagination
                                currentPage={currentPage}
                                totalCount={listings.length}
                                pageSize={PageSize}
                                siblingCount={1}
                                onPageChange={page => setCurrentPage(page)}
                            />}
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </>
    )
}

export default SearchPlain